//import axios from 'axios';
import http from '../../axios/axios_conf';
import api from '../../routers/routers';

class Posts {
 
  list(post_data){
    return http.get( api.posts.get.endpoint, post_data);
  }

  create(file,params){
    return http.post( api.posts.create.endpoint+params, file ,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    } );
  }
  
  update(post_data,params){
    return http.put( api.posts.update.endpoint+params, post_data );
  }
  
  delete(post_data){
    return http.delete( api.posts.delete.endpoint+post_data );
  }

}

export default new Posts();